/* eslint-disable */
export default [
  {
    header: "Gest. Magasin",
    action: "read_all_stores",
    resource: "Magasins",
  },
  {
    title: "Calendrier",
    route: "apps-calendar",
    icon: "CalendarIcon",
    action: "read_all_stores",
    resource: "Magasins",
  },
  {
    title: "Magasins",
    icon: "LayersIcon",
    children: [
      {
        title: "Liste",
        route: "apps-stores-list",
        action: "read_all_stores",
        resource: "Magasins",
      },
      // {
      //   title: "Détails",
      //   route: {
      //     name: "apps-store-details",
      //     params: { id: 23 },
      //   },
      //   action: "read_store",
      //   resource: "Magasins",
      // },
      // {
      //   title: "En attente",
      //   route: "apps-store-products-queue",
      //   action: "read_all_stores",
      //   resource: "Magasins",
      // },
      {
        title: "Planifier",
        route: {
          name: "apps-store-product-checkout",
          params: { id: 23 },
        },
        action: "edit_store",
        resource: "Magasins",
      },
    ],
  },
  // {
  //   title: 'Stocks',
  //   route: 'apps-item-stocks-list',
  //   icon: 'DollarSignIcon',
  //   action: 'read_all_stocks',
  //   resource: 'Stocks',
  // },
  // {
  //   title: 'Produits',
  //   route: 'apps-items-list',
  //   icon: 'DollarSignIcon',
  //   action: 'read_all_products',
  //   resource: 'Produits',
  // },
  // {
  //   title: "Calendrier",
  //   route: "apps-calendar",
  //   icon: "TrendingUpIcon",
  //   action: 'read_calendar',
  //   resource: 'Calendrier',
  // },
];
